import React from "react"
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LargeImageSliderStyles, SliderContent, Overlay, CallToAction } from './LargeImageSliderStyles';

export const LargeImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <LargeImageSliderStyles className="section">
    <div className="container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <SliderContent key={index}>
          {image.videoUrl ? (<div className="video-container">
            <div className="video-loading">
                <img src="/images/loader.gif" alt="Loading Video..." />
              </div>
            <iframe src={image.videoUrl} allow="autoplay; fullscreen;" title={image?.imageTitle}></iframe>
            </div>) : (
              <>
              <GatsbyImage image={getImage(image.image)} alt={image?.imageTitle || null} />
              <Overlay>
                <div className="overlay-content">
                  {/* Add your overlay text and CTA button here */}
                  <h2>{image.displayTitle}</h2>
                  {image?.imageSubHeading && <p>{image.imageSubHeading}</p>}
                  {image.callToActionText && image.callToActionLink && <CallToAction to={image.callToActionLink}>{image.callToActionText}</CallToAction>}
                </div>
              </Overlay>
              </>
            )
          }
          </SliderContent>
        ))}
      </Slider>
      </div>
    </LargeImageSliderStyles>
  );
};

// Previous Arrow component
const PrevArrow = (props) => {
    const { onClick } = props;
    return <Arrow onClick={onClick} left className="hide-arrow"><SlArrowLeft /></Arrow>;
  };
  
  // Next Arrow component
  const NextArrow = (props) => {
    const { onClick } = props;
    return <Arrow onClick={onClick} right><SlArrowRight /></Arrow>;
  };
  
  const Arrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 2px;
    padding: 6px 8px 0px;
    background-color: #f6d134;
    font-size: 34px;
    color: #000;
    cursor: pointer;
    z-index: 1;
    ${({ left }) => left && 'left: 3.5%;'};
    ${({ right }) => right && 'right: 3.5%;'};
    &.hide-arrow {
      display: none;
    }
  `;