import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import { LargeImageSlider } from "../components/LargeImage/LargeImageSlider"
import { ClientSlider } from "../components/ClientsSlider/ClientSlider"
import { TileSlider } from "../components/TileSlider/TileSlider"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"
import Services from "../components/Services/Services"
// TODO replace DATA files below with contentful content graphql call for news and campaigns
import { tileData } from '../components/TileSlider/TileSlider.data';
import { transformTileContent } from '../utils/transform-data-functions';

const Index = ({ data: { homepage, clients, campaigns, news } }) => {

  const campaignsData = transformTileContent(campaigns.nodes);
  const newsData = transformTileContent(news.nodes);
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title={homepage.header}
          subTitle={homepage.subHeader}
        />
        <LargeImageSlider images={homepage.homePageSlider.slides} />
        <Services title={homepage.servicesTitle} subheading={homepage.servicesSubHeading} />
        <ClientSlider heading={homepage.ourClientsTitle} logos={clients.nodes} />
        <TileSlider heading="Campaigns" intro="Our amazing team deliver incredible results across a variety of specialist services." tiles={campaignsData} ctaText="View All" ctaLink="/campaigns" />
        <TileSlider heading="News" intro="Here are some highlights of the latest news from 100% Marketing..." tiles={newsData} ctaText="View All" ctaLink="/news" />
      </Layout>
    </>
  )
}

export const data = graphql`
query homepageQuery {
  homepage: contentfulHomePage(contentful_id: {eq: "5jgdz28QuNVzoAcLGwZFLs"}) {
    header
    subHeader
    servicesTitle
    servicesSubHeading
    ourClientsTitle
    campaignsTitle
    campaignsSubHeading
    newsTitle
    newsSubHeading
    homePageSlider {
      sliderTitle
      slides {
        imageTitle
        displayTitle
        imageSubHeading
        callToActionText
        callToActionLink
        videoUrl
        image {
          description
          gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
    mainImage {
      gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      description
    }
  }
  clients: allContentfulClients(sort: {order: ASC, fields: position}) {
    nodes {
      internal {
        type
      }
      title
      logo {
        file {
          url
        }
      }
      website
    }
  }
  campaigns: allContentfulCampaign {
    nodes {
      internal {
        type
      }
      title
      thumbnail {
        description
        gatsbyImageData(
          width: 384
          height: 421
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        file {
          url
        }
      }
      caseStudy
      internalLink {
        internal {
          type
        }
        url
      }
      externalLink
    }
  }
  news: allContentfulNews(
    sort: {fields: datePublished, order: DESC}
    filter: {enabled: {eq: true}}
  ) {
    nodes {
      internal {
        type
      }
      title
      datePublished
      url
      thumbnail {
        description
        gatsbyImageData(
          width: 384
          height: 421
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        file {
          url
        }
      }
    }
  }
}
`

export default Index
